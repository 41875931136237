import React, { useState } from 'react';
import { TypoXS } from '@atoms/Typos';
import { useAppSelector } from '@hooks/useAppSelector';
import { useNeighbor } from '@hooks/useNeighbor';
import { allRegion, areaTabKeys } from '@utils/constants';
import { pushDataLayer } from '@utils/spa-ga4';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import styles from './css';

type Props = {
  getPropertiesByArea: (
    locale?: string,
    areaTabKey?: string,
    authUser?: any
  ) => void;
};

const AreaTabs = ({ getPropertiesByArea }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation('browse');
  const [selectedAreaKey, setSelectedAreaKey] = useState(allRegion);
  const { authUser } = useAppSelector((state) => ({
    authUser: state.authUser,
  }));
  const { neighbor } = useNeighbor();

  const onClickTab = (areaTabKey: string) => {
    setSelectedAreaKey(areaTabKey);
    getPropertiesByArea(locale, areaTabKey, authUser);
    pushDataLayer({
      campaign_code: '',
      event: 'gtm-click',
      event_name: `button_property-${areaTabKey}`,
      neighbor_id: neighbor?.id,
    });
  };

  return (
    <div className="area-tabs">
      {areaTabKeys.map((areaTabKey) => {
        const isSelected = areaTabKey === selectedAreaKey;

        return (
          <button
            key={areaTabKey}
            className={classNames('area-tab-button', {
              selected: isSelected,
            })}
            onClick={() => onClickTab(areaTabKey)}
            type="button"
          >
            <TypoXS
              color={isSelected ? 'black900' : 'black600'}
              text={t(`areaTabs.${areaTabKey}`)}
            />
          </button>
        );
      })}
      <style jsx={true}>{styles}</style>
    </div>
  );
};

export default AreaTabs;
